.vgs-widget,
.vgs-product-tile {
  &.ant-card-bordered {
    border-color: theme('borderColor.neutral.200');
  }
}

.vgs-widget {
  &.ant-card {
    margin-bottom: 2rem;
  }

  &.ant-card > .ant-card-body {
    > .ant-card-content {
      padding: 0;
    }
  }
}

.vgs-product {
  &-icon {
    height: 4rem;
    width: 4rem;
    margin-right: 1rem;
  }

  &-tile {
    cursor: pointer;
    flex: 1;

    &.ant-card-bordered:hover {
      border-color: theme('borderColor.primary.500');
      color: theme('colors.primary.500');
    }

    &.ant-card {
      margin: 1rem;
    }

    &.ant-card .ant-card-body,
    .tw-subhead,
    .tw-text-body-b2 {
      color: inherit;
    }
  }
}

.vgs-organizations-list {
  @apply tw-flex tw-flex-col;

  max-height: 400px;
  overflow: auto;
}

.vgs-organization-row {
  @apply tw-flex tw-justify-between tw-p-6;

  &:not(:last-child) {
    border-bottom: 1px solid theme('borderColor.neutral.200');
  }
}
