.header {
  background: theme('colors.white');
  border-bottom: 1px solid theme('colors.neutral.200');
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  height: var(--header-height);
  z-index: 99;
  width: 100%;

  &.with-sidebar {
    width: calc(100% - var(--nav-sidebar-width));
  }
}

.user-profile {
  @apply tw-flex tw-h-full tw-items-center tw-ml-5;

  &-info {
    @apply tw-flex tw-items-center tw-cursor-pointer;

    max-width: 250px;
  }

  &-dropdown {
    .ant-popover-inner-content {
      padding: 0;
      width: 200px;
    }

    .ant-menu {
      border: none;
      padding: 0;
    }

    .ant-menu-inline .ant-menu-item {
      margin: 0;
      width: 100%;
    }

    .ant-menu-item:hover {
      color: initial;
      background: var(--primary-color-100);
    }
  }
}
