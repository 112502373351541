.nav-sidebar {
  background: theme('colors.primary.100');
  border-right: 1px solid theme('borderColor.default');
  width: var(--nav-sidebar-width);

  &-header {
    @apply tw-flex tw-items-center tw-px-6;
    height: var(--header-height);
  }

  &-link {
    cursor: pointer;
    line-height: 32px;

    &:hover,
    &.active {
      background: theme('colors.primary.200');
    }

    &-item {
      @apply
        tw-flex
        tw-items-center
        tw-px-6
        tw-no-underline
        hover:tw-no-underline
        hover:tw-text-current;

      color: theme('colors.neutral.700');
      height: 32px;
      width: 100%;
    }
  }
}
