@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/base";
@import "styles/colors";
@import "styles/antd/popover";

@import "components/organisms/header/header";
@import "components/organisms/navigation-sidebar/navigation-sidebar";
@import "components/organisms/organization-switcher/organization-switcher";
@import "components/templates/welcome-widget/welcome-widget";

@import "pages/dashboard/dashboard";
