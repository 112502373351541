.widget {
  height: calc(100vh - 2 * var(--widget-offset));
  width: 100%;
  max-width: 1400px;

  &-content {
    max-width: 500px;
  }

  &-actions {
    height: var(--widget-footer-height);
  }

  &.ant-card {
    margin: auto;

    .ant-card-body,
    .ant-card-content {
      height: 100%;
      padding: 0;
    }
  }

  h2.tw-header {
    margin-bottom: 2rem;
  }
}
